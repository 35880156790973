<template>
  <div class="modal team">
    <div class="box one mHeader flexB">
      <h1>{{ $t("user-data-check-out-memo") }}</h1>
      <a @click="close"><i class="fas fa-times"></i></a>
    </div>
    <div class="box one mBody">
      <div style="width: 100%; height: 150px; resize: none; word-break: break-all; font-size: 1.2rem;">
        > 특이사항 내용<br/>
        > 체크 아웃 시 저장한 메모
        <div style="width: 100%; height: calc( 100% - 25px ); ">
          <textarea style="width: 100%; height: 100%; resize: none; border:none; overflow-y:scroll;" readonly  v-model="memo"></textarea>
        </div>
      </div>
    </div>
      <div class="buttonWrap">
<!--        <button class="point large" @click="submit">{{ $t("btn-select") }}</button>-->
        <button class="point large" @click="close">{{ $t("btn-confirm") }}</button>
      </div>
  </div>
</template>

<script>
export default {
  props:{
    memo : {
      default : null,
    }
  },
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },


    submit() {
      //this.$emit("submit", this.memo);
    },
  },
};
</script>
